

import TextEditor from "../TextEditor";
import "./index.css"
const BlogCreatePage=()=>{
    return(
    <>
    <div className="write">
        <div className="content"> <input type="text"  placeholder="Title"/>
        <div className="editorContainer">
        <TextEditor className="editor"/>
     
        </div>
              content</div>
            <div className="menu">
                <div className="item"><h1>Publish</h1>
                <span><b>status:</b> Draft</span>
                <span><b>Visibility:</b> Public</span>
                <input styel={{display:"none"}}type="file" name="" id="file"/>
                <label htmlFor="file">Upload image</label>
                <div className="button">
                <button>Save as a Draft</button>
                <button>Update</button>
                </div>
               
                </div>
                <div className="item"><h1>Category</h1>
                <input type="radio" name='category' value="art"  id='art'/></div>
                <label htmlFor="art">art</label>
            </div>
        
    </div>
    </>
    )
}
export default BlogCreatePage;