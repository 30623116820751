import axios from "axios"
import { useSelector } from "react-redux"
const { REACT_APP_BASE_API_URL } = process.env

// ============================== API - AUTHENTICATION ==============================



const instance = axios.create({
    baseURL: `${REACT_APP_BASE_API_URL}`,
  });
  export default instance
  