
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';

import MainAdminDasboard from './routes/maindashboard';
import BlogCreatePage from './routes/blog';
function App() {
  return (
    <>
    <Routes>
      
      <Route path='/' element={<MainAdminDasboard />} />
      <Route path='blog-creation-page' element={<BlogCreatePage />} />
    </Routes>
    </>
  );
}

export default App;
