
import React, { useCallback, useMemo, useState ,useEffect} from 'react';
import { MaterialReactTable } from 'material-react-table';
import instance from '../../../api/authentication';
import { MuiFileInput } from 'mui-file-input'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

// import instance from '../../../api/authentication';

import InputLabel from '@mui/material/InputLabel';
import { Textarea } from '@mui/joy';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const ContentCreationPage=({ open, columns, onClose, onSubmit,})=>{

    const[productItem,setProductItem]=useState([]);
    const [file, setFile] = React.useState([]);
    const [formatData, setFormatData]=useState({});
    const [opens, setOpen] = React.useState(false);
    const[categoryItem,setCategoryItem]=useState([])
    const [age, setAge] = React.useState({menu:'',session:''});
    const [tableData, setTableData] = useState([]);
    const [menuData, setMenuData]=useState([]);
    const [formValues, setFormValues] = useState([]);
  const [pageData, setPageData] = useState([])
  const[attachment,setAttachment]=useState([]);
  const[fieldValues,setFieldValues]=useState([]);
  const [Values, setValues] = useState([{ name: "", fieldname: "",pageName:"" ,fieldvalue:"",attachementfield:"",attachementValue:""}])


  let handleChange = (i, e) => {
    console.log(formatData)
    let newFormValues = [...Values];
    newFormValues[i][e.target.name] = e.target.value;
    setValues(newFormValues);
   let newOne=Values.map((item)=>{
    return({
      [item.fieldname]:item.fieldvalue,
      [item.attachementfield]:formatData
    })
   })
   let finalObj = {};

// loop elements of the array 
for(let i = 0; i < newOne.length; i++ ) {
  Object.assign(finalObj, newOne[i]);
}

console.log("\nAfter converting array of objects to single object");
console.log(finalObj);
setFormValues(finalObj)
}
let addFormFields = () => {
    setValues([...Values, { }])
  }


  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }

  











    const handleChangeFile = (newFile) => {
      if(newFile){
        const formData = new FormData();
        formData.append('file', newFile);
      instance
        .post(`/upload`,formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
        .then((response)=>{
          const data = response.data;
          setFormatData(data.data);
          console.log(formatData)
          
        })
      
    }
  setFile(newFile)
      
    }




    // const handleChangeFile = (newFile) => {
    //   if(newFile){
    //     const formData = new FormData();
    //     formData.append('file', newFile);
    //   instance
    //     .post(`/upload`,formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
    //     .then((response)=>{
    //       const data = response.data;
    //       setFormatData(data.data);
    //       console.log(formatData)
          
    //     })
      
    // }
    //   setFile(newFile) 
    // }

    
     //Api call for menu listing
  const getPageList =()=>{
    instance
    .get(`/getPageList`)
    .then((response)=>{
      if(response.data.message == "Success"){
        setMenuData(response.data.data)
        //  setCreateModalOpen(false)
        //  setEditModalOpen(false)
      }
    })
  }
  useEffect(()=>{
    getPageList();
  },[])

  
 //Api call for session listing
 const getsessionList =()=>{
  instance
  .get(`/getPageDetailsList`)
  .then((response)=>{
    if(response.data.message == "Success"){
      setTableData(response.data.data)
      //  setCreateModalOpen(false)
      //  setEditModalOpen(false)
    }
  })
}
useEffect(()=>{
  getsessionList();
},[])
 
  console.log(formValues)
  console.log(Values)
    //!-----=======Api call for image upload =========----!
  
  
  //!----========Api call for product submit =========----!
 
console.log(productItem.session)
  const handleonSubmit =(productItem)=>{
  
  
   
      // console.log(formatData)
    const postData={
      pageDetailRef:productItem.session._id,
      content:[{
        [productItem.session.name]:[formValues]

      }]
     
    };
    instance
    .post(`/createContent`, postData)
    .then((Response)=>{
      console.log(Response)
    
      setFile({})
      onClose();
    })
  }
  return(
    <Dialog open={open}>
        <DialogTitle textAlign="center">Create New Product</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
                {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Menu</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
         name='menu'
         value={menuData.menu}
          label="Menu"
          onChange={(e) =>
            setProductItem({ ...productItem, [e.target.name]: e.target.value })
          }
        >
         <MenuItem value="">
              <em>None</em>
            </MenuItem> 
           {menuData?.map((state)=>{
              return(
                <MenuItem key={state._id} value={state._id}>
              {state.name}
            </MenuItem> 
              )
              
            })}
        </Select>
       
      </FormControl> */}

      <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-helper-label">Session</InputLabel>
        <Select
         name='session'
        //  value={menuData.session}
          label="Session"
          onChange={(e) =>
            setProductItem({ ...productItem, [e.target.name]: e.target.value })
          }
         
        >
        <MenuItem value="">
              <em>None</em>
            </MenuItem> 
          {tableData?.map((state)=>{
              return(
                <MenuItem key={state._id} value={state}>
              {state.name}
            </MenuItem> 
              )
              
            })} 
        </Select>
     
      </FormControl>
              
             
        {/* <TextField
                  // key={}
                  label='Tittle'
                  name='tittle'
                  onChange={(e) =>
                    setProductItem({ ...productItem, [e.target.name]: e.target.value })
                  }
                /> */}
                  {/* <Textarea
    placeholder="Try to submit with no text!"
    name='textarea'
    required
    sx={{ mb: 1 }}
    onChange={(e) =>
      setProductItem({ ...productItem, [e.target.name]: e.target.value })
    }
  /> */}
  {/* <h2>Heading<button onClick={() =>{ addFormFields();setFieldValues("head")}}>+</button></h2>
      */}
     {(Values.map((element, index) => (
       <div className="form-inline" key={index}>
         
         
         <h3>Fields<button onClick={() =>{ addFormFields();setFieldValues("field")}}>+</button></h3>
         <label>Field Name</label>
         <input type="text" name="fieldname" value={element.fieldname}  onChange={e => handleChange(index, e)} /><br />
         <label>Field Value</label>
         <Textarea
    // placeholder="Try to submit with no text!"
    name="fieldvalue" 
    required
    sx={{ mb: 1 }}
    value={element.fieldvalue} onChange={e => handleChange(index, e)}
  />
         {/* <input type="text" name="fieldvalue"  value={element.fieldvalue} onChange={e => handleChange(index, e)} /> */}
         <h3>Attachement Fields</h3>

         <label>attachement field</label>
         <input type='text' name='attachementfield' value={element.attachementfield} onChange={e => handleChange(index, e)}
         ></input><br />
         <label>field Value</label>
      
               <MuiFileInput 
     label="Attachment"    value={file} onChange={handleChangeFile }  />
         {/* <input type='text' name='attachementValue' value={element.attachementValue} onChange={e => handleChange(index, e)}></input><br /><br /> */}


         {
           index ?
             <button type="button" className="button remove" onClick={() => removeFormFields(index)}>Remove</button>
             : null
         }
       </div>
     )))}
     {/* {fieldValues=='field'?( (formValues.map((element, index) => (
       <div className="form-inline" key={index}>
         
        
         <label>Field Name</label>
         <input type="text" name="field" value={element.field}   onChange={e =>handleChange1(index, e)} /><br />
         <label>Field Value</label>
         <input type="text" name="value"  value={element.value} onChange={e => handleChange1(index, e)} /><br />
         <label>attachement field</label>
         <input type='text' name='attachementfield' value={element.attachementfield} onChange={e => handleChange1(index, e)}
         ></input><br />
         <label>field Value</label>
         <input type='text' name='attachementValue' value={element.attachementValue} onChange={e => handleChange1(index, e)}></input><br /><br />
       

         {
           index ?
             <button type="button" className="button remove" onClick={() => removeFormFields(index)}>Remove</button>
             : null
         }
       </div>
     )))):""} */}
               {/* <MuiFileInput 
     label="Attachment"  value={file}  /> */}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="secondary" onClick={()=>handleonSubmit(productItem)}  variant="contained">
           Submit
          </Button>
        </DialogActions>
      </Dialog>
  );
  };
  
export default  ContentCreationPage;
