


import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
  } from '@mui/material';



const EditSessionDetails=({sessionDetail})=>{

const Banner=({...sessionDetail.banner})
const arrayOfObjects = Object.values( Banner);
console.log(arrayOfObjects)
//  const con=sessionDetail.banner
//  sessionDetail.banner.forEach(function(item) {
//   Object.keys(item).forEach(function(key) {
//     console.log("key:" + key ,+ " value:" + item[key]);
//   });
// });
// console.log(Object.entries((con.map((item)=>
// item))).join(','));
//  const con=(sessionDetail[0])
//   let entries = Object.entries(con)
//   console.log(entries)

    return(
    <>
  
{arrayOfObjects.length>0 ?(arrayOfObjects.map((item,index)=>{
 return(<>
   <TextField
                  // key={}
                  label='Session'
                  name='session'
                  defaultValue={item.subvalue1}
                  // onChange={(e) =>
                  //   setProductItem({ ...productItem, [e.target.name]: e.target.value })
                  // }
                />
  </>)
   
   
 
})):""}
  

   
    </>
    )
}
export default EditSessionDetails;