

import TextEditor from "../TextEditor";
import MenuTabPage from "../menu";
import AdminSessionPage from "../session";
import ContentPage from "../content";
import BlogCreatePage from "../blog";
const AccruonAdminDasboard=({component})=>{
    return(
        <>
        {
                component == 'menu' ? (<MenuTabPage></MenuTabPage>)
                :component =='session' ?(<AdminSessionPage></AdminSessionPage>)
                :component =='content'?(<ContentPage/>)
                :component =='Blog'?(<BlogCreatePage></BlogCreatePage>)
                    
                        : "hlo"
            }
     
      
        </>
    )
}
export default AccruonAdminDasboard;